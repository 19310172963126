'use client';

import Image from '@src/components/coreUI/image';
import React from 'react';

function Loading() {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="relative">
        <div className="pageloader"></div>
        <Image
          src="/zeme-icon.png"
          alt="Loader"
          className="w-10 absolute top-[20px] left-[18px]"
        />
      </div>
    </div>
  );
}

export default Loading;
